export const DailySchedOpts = {
  EVERYDAY: 'everyday',
  MONTOFRI: 'm_f',
  NEVER: 'never',
};

export const DailySchedOptTitles = {
  EVERYDAY: 'Every day',
  MONTOFRI: 'Monday - Friday',
  NEVER: 'Never',
};

export const AlertTypes = {
  WARN: 'warn',
  PAST_DUE: 'pastDue',
};

export const ScheduleTypes = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
  REVIEW: 'review',
};
